<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2 triggerButton"
        v-bind="attrs"
        v-on="on"
      >
        Submit Feedback
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Provide Feedback</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="cols"
            >
              <v-text-field
                v-model="field.email"
                label="Email"
                disabled
              />
            </v-col>

            <v-col
              cols="12"
              class="cols"
            >
              <v-select
                v-model="field.feedbackType"
                :items="feedbackTypes"
                label="Feedback Type *"
                item-text="name"
                item-value="id"
                required
                :error="!field.feedbackType && isFormDirty"
              />
            </v-col>
            <v-col
              cols="12"
              class="cols"
            >
              <v-textarea
                v-model="field.feedbackText"
                name="input-7-1"
                label="Your feedback *"
                hint="Provide your feedback here"
                required
                :error="!field.feedbackText && isFormDirty"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="submitFeedback"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import AuthService from '@/services/AuthService'
  const auth = new AuthService()

  export default {
    name: 'FeedbackDialog',
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      user: null,
      dialog: false,
      feedbackTypes: [{
        name: 'Report a bug',
        id: 'bug',
      }, {
        name: 'Request an enhancement',
        id: 'enhancement',
      }],
      field: {
        email: '',
        feedbackType: '',
        feedbackText: '',
      },
      username: '',
      isFormDirty: false,
      snackbar: false,
      notification: 'Unknown',
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async created () {
      this.username = localStorage.getItem('username')
      this.user = await auth.getUser()
      this.field.email = this.username
      this.field.name = this.user.profile.name
      await this.initialize()
    },

    methods: {
      initialize () {},

      close () {
        this.dialog = false
        this.field = {
          name: '',
          email: '',
          feedbackType: '',
          feedbackText: '',
        }
        this.isFormDirty = false
        this.snackbar = false
      },

      isFormValid () {
        let isValid = true
        if (!this.field.feedbackType) {
          isValid = false
        }
        if (!this.field.feedbackText) {
          isValid = false
        }
        return isValid
      },

      submitFeedback () {
        this.isFormDirty = true
        if (!this.isFormValid()) {
          this.notification = 'Fill all the required fields'
          this.snackbar = true
          return
        }
        this.$emit('on-submission-start')

        /** Prepare params for API call **/
        const params = {
          FeedbackType: this.field.feedbackType,
          FeedBack: this.field.feedbackText,
          FromEmail: this.field.email,
        }
        this.httpConnector.makeRequest('post', `${this.apiEndpoint}emailfeedback?code=${this.apiCode}`, params)
          .then((res) => {
            this.$emit('on-success', res)
            this.close()
          })
          .catch((error) => {
            this.$emit('on-error', error)
          })
      },
    },
  }
</script>

<style lang="scss">
.cols {
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}
.v-card .v-card__actions {
    margin-top: -35px;
}
.triggerButton {
  width: 100%;
}
</style>
